import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'

export const ComparetableWrap = styled.div`
  .phone-item {
    display: none;
  }
  #tableHeader > div {
    background: #fff;
    border-radius: 0;
    box-shadow: 0px 1px 0px 0px #eeeeee;
  }
  .table-row {
    &:hover {
      background: #f5f7ff;
      box-shadow: unset;
    }
  }
  .learn-more {
    margin-top: 22px;
  }
  .c-title {
    margin-bottom: 32px;
  }
  ${Media.homeLaptop`
    .c-title {
      margin-bottom: 24px;
    }
  `}
  ${Media.laptop`
    .c-title {
      margin-bottom: 16px;
    }
  `}
  ${Media.laptop`
    .c-title {
      margin-bottom: 8px;
    }
  `}
  ${Media.phone`
    .web-item {
      display: none;
    }
    .phone-item {
      display: block;
    }
    .learn-more {
      margin-top: 0.4rem;
    }
    .c-title {
      margin-bottom: 0.32rem;
    }
    .table-row {
      &:hover {
        background: transparent;
      }
    }
  `}
`

export const TableTitle = styled.div`
  text-align: center;
  .t-title {
    font-size: 20px;
    font-weight: 700;
  }
  .t-desc {
    font-size: 16px;
    margin: 2px auto 8px;
    font-weight: 500;
    span {
      margin: 0 0 0 2px;
      font-size: 24px;
      font-weight: 700;
    }
    &::before {
      content: 'US$';
      font-size: 16px;
      font-weight: 700;
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: 12px;
    padding: 10px 12px;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 500;
    color: #fff;
    background: linear-gradient(290.07deg, #445fff 53.77%, #55bcee 97.08%);
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.05) 0%,
          rgba(255, 255, 255, 0.05) 100%
        ),
        linear-gradient(
          290deg,
          rgb(68, 95, 255) 53.77%,
          rgb(85, 188, 238) 97.08%
        );
    }
  }
  .line {
    border: 1px solid #445fff;
    background: transparent;
    color: #445fff;
    &:hover {
      background: rgba(245, 247, 255, 1);
    }
  }
  ${Media.phone`
    padding: 0 0.14rem;
    .t-title {
      font-size: 0.28rem;
    }
    .t-desc {
      margin: 0.04rem auto 0.16rem;
      font-size: 0.28rem;
      font-weight: 400;
      span {
        font-size: 0.28rem;
        font-weight: 700;
      }
      &::before {
        font-size: 0.28rem;
        font-weight: 700;
      }
    }
    .btn {
      padding: 0.16rem 0.2rem;
      font-size: 0.28rem;
      border-radius: 0.06rem;
      white-space: nowrap;
    }
  `}
`
