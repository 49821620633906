import React from 'react'
import { Wrapper, Content } from './atoms'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import Path from '@raylink-overseas/common/constants/path'
import logoImg from '../../../../../images/products/personal/avica-logo.svg'

const AvicaApp = () => {
  return (
    <Wrapper>
      <Content>
        <img src={logoImg} alt="logo" loading="lazy" />
        <h2>
          Your Preferred Choice for <span>Remote Desktop App</span>
        </h2>
        <ButtonList
          btnInfo={[
            {
              text: 'Free Download',
              href: Path.downloads,
            },
            {
              text: 'See Pricing',
              href: Path.pricing,
            },
          ]}
        />
      </Content>
    </Wrapper>
  )
}
export default AvicaApp
