import styled from 'styled-components'
import { NewWrapper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'

export const BannerWrapper = styled.div`
  height: 752px;
  padding-top: 82px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 55.39%,
      rgba(255, 255, 255, 0.5) 77.69%,
      rgba(255, 255, 255, 0.7) 85.11%,
      #fff 100%
    ),
    radial-gradient(
      62.64% 94.22% at 47.55% -38.9%,
      rgba(68, 95, 255, 0.27) 0%,
      rgba(147, 187, 254, 0) 100%
    ),
    radial-gradient(
      82.5% 27.5% at 28.91% 43.75%,
      rgba(225, 233, 255, 0.8) 0%,
      rgba(220, 230, 255, 0) 100%
    ),
    linear-gradient(
      69deg,
      #f0f6fe -7%,
      #c6e3fb 2.95%,
      #a8d6f9 16.47%,
      #b4dbfc 35.03%,
      #c3d1fb 63.16%,
      #e4d7fe 86.93%,
      #f8f3ff 98.8%
    );
  ${Media.widthScreen`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 55.39%, rgba(255, 255, 255, 0.50) 77.69%, rgba(255, 255, 255, 0.70) 85.11%, #FFF 100%), radial-gradient(62.64% 94.22% at 47.55% -38.9%, rgba(68, 95, 255, 0.27) 0%, rgba(147, 187, 254, 0.00) 100%), radial-gradient(82.5% 27.5% at 28.91% 43.75%, rgba(225, 233, 255, 0.80) 0%, rgba(220, 230, 255, 0.00) 100%), linear-gradient(69deg, #F0F6FE -7%, #C6E3FB 2.95%, #A8D6F9 16.47%, #B4DBFC 35.03%, #C3D1FB 63.16%, #E4D7FE 86.93%, #F8F3FF 98.8%);
  `}
  ${Media.laptop`
    height: 638px;
  `}
  ${Media.ipad`
    height: 536px;
  `}
  ${Media.phone`
    height: 12rem;
    background: none;
    padding-top: 1.6rem;
  `}
`

export const BannerContent = styled(NewWrapper)`
  display: flex;
  justify-content: space-between;
  ${Media.phone`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 68.75%, rgba(255, 255, 255, 0.50) 84.38%, rgba(255, 255, 255, 0.70) 89.57%, #FFF 100%), radial-gradient(62.64% 94.22% at 47.55% -38.9%, rgba(68, 95, 255, 0.27) 0%, rgba(147, 187, 254, 0.00) 100%), radial-gradient(169.81% 42.22% at 50.13% 28.75%, rgba(225, 233, 255, 0.80) 0%, rgba(220, 230, 255, 0.00) 100%), linear-gradient(68deg, #F0F6FE 9.96%, #C6E3FB 18.31%, #A8D6F9 29.67%, #B4DBFC 45.26%, #C3D1FB 68.88%, #E4D7FE 88.84%, #F8F3FF 98.82%);
    flex-direction: column;
    align-items: center;
  `}
`

export const Left = styled.div`
  width: 480px;
  padding-top: 70px;
  .tag {
    width: fit-content;
    padding: 6px 16px;
    border-radius: 42px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(3px);
    font-size: 16px;
    color: #1f2329;
  }
  h1 {
    margin: 16px 0;
    font-size: 40px;
    font-weight: 800;
    color: #1f2329;
  }
  .desc {
    color: #595959;
    font-size: 18px;
    font-weight: 400;
  }
  .btn-list-div {
    margin-top: 40px;
  }
  .available-list {
    margin-top: 24px;
    z-index: 1;
  }
  ${Media.laptop`
    h1 {
      margin: 12px 0;
      line-height: 45.4px;
    }
    .btn-list-div {
      margin-top: 32px;
    }
  `}
  ${Media.ipad`
    width: 366px;
    padding-top: 30px;
    h1 {
      font-size: 34px;
      margin: 12px 0;
      line-height: 38.9px;
    }
    .desc {
      font-size: 16px;
    }
    .btn-list-div {
      margin-top: 32px;
    }
    .available-list {
      margin-top: 16px;
    }
  `}
  ${Media.phone`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .tag {
      font-size: 0.28rem;
    }
    h1 {
      font-size: 0.52rem;
      margin: 0.16rem 0;
      line-height: 0.58rem;
    }
    .desc {
      font-size: 0.28rem;
      width: 5.72rem;
    }
    .btn-list-div {
      margin-top: 0.48rem;
    }
    .available-list {
      margin-top: 0.32rem;
    }
  `}
`
export const Right = styled.img`
  width: 694px;
  height: 619px;
  ${Media.homeLaptop`
    width: 661px;
    height: 590px;
  `}
  ${Media.laptop`
    width: 608px;
    height: 540px;
  `}
  ${Media.ipad`
    width: 523px;
    height: 461px;
  `}
  ${Media.phone`
    width: 6.86rem;
    height: 5.88rem;
    margin-top: -0.3rem;
  `}
`
