import React, { useState, useEffect } from 'react'
import { Layout } from '@raylink-overseas/common/components'
import {
  PersonalBanner,
  ProductsIntroduce,
  ProductsFeature,
  ProductsScene,
  ProductsCompare,
  AvicaApp,
} from './components'
import { PersonalWrap } from './atoms'
import { isElementInViewport } from '@raylink-overseas/common/utils'

const PersonalProducts = () => {
  const [introduceOnePlay, setIntroduceOnePlay] = useState(false)
  const [introduceTwoPlay, setIntroduceTwoPlay] = useState(false)
  const [featurePlay, setFeaturePlay] = useState(false)
  const [scenePlay, setScenePlay] = useState(false)
  useEffect(() => {
    // 有播放效果的组件，当组件出现在浏览器可视化区域大于自身1/2时开始播放效果
    const introOneEl = document.getElementById('intro-one-id')
    const introTwoEl = document.getElementById('intro-two-id')
    const featureEl = document.getElementById('feature-id')
    const sceneEl = document.getElementById('scene-id')
    const handle = () => {
      setIntroduceOnePlay(!!(introOneEl && isElementInViewport(introOneEl)))
      setIntroduceTwoPlay(!!(introTwoEl && isElementInViewport(introTwoEl)))
      setFeaturePlay(!!(featureEl && isElementInViewport(featureEl)))
      setScenePlay(!!(sceneEl && isElementInViewport(sceneEl)))
    }
    window.addEventListener('scroll', handle)

    return () => {
      window.removeEventListener('scroll', handle)
    }
  }, [])
  return (
    <Layout pageType="productPersonal">
      <PersonalWrap>
        <PersonalBanner />
        <ProductsIntroduce
          playStatus={introduceOnePlay}
          type="remoteExperience"
        />
        <ProductsIntroduce playStatus={introduceTwoPlay} type="firstPriority" />
        <ProductsFeature isPlay={featurePlay} />
        <ProductsScene isPlay={scenePlay} />
        <ProductsCompare />
        <AvicaApp />
      </PersonalWrap>
    </Layout>
  )
}

export default PersonalProducts
