import { NewWrapper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'
import styled from 'styled-components'

export const SceneWrapper = styled.div``
export const SceneContent = styled(NewWrapper)`
  .title {
    margin-top: 0;
    .desc a {
      font-size: 18px;
    }
  }
  ${Media.phone`
    .title .desc a {
      font-size: 0.28rem;
    }
  `}
`

export const SceneTitle = styled.div`
  display: flex;
  justify-content: center;
  gap: 48px;
  ${Media.ipad`
    gap: 16px;
  `}
  ${Media.phone`
    display: none;
  `}
`
export const TitleItem = styled.div<{ activate: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 222px;
  gap: 8px;
  cursor: pointer;
  .name {
    font-size: 20px;
    font-weight: 500;
    color: #1f2329;
    line-height: 25.35px;
    ${({ activate }) => activate && 'color: #445fff; font-weight: 700;'}
  }
  &:hover {
    .name {
      color: #445fff;
      font-weight: 700;
    }
  }
  ${Media.ipad`
    width: 200px;
    gap: 4px;
    .name {
      font-size: 18px;
    }
  `}
`

export const TitleImg = styled.div<{
  img: string
  hoverImg: string
  activate: boolean
}>`
  width: 88px;
  height: 88px;
  background-image: ${({ img }) => `url(${img})`};
  background-size: cover;
  ${({ activate, hoverImg }) =>
    activate && `background-image: url(${hoverImg});`}
  &:hover {
    background-image: ${({ hoverImg }) => `url(${hoverImg})`};
  }
  ${Media.ipad`
    width: 80px;
    height: 80px;
  `}
`
export const SceneMain = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 56px;
  height: 400px;
  border-radius: 24px;
  margin-top: 40px;
  padding: 24px 24px 24px 64px;
  background: radial-gradient(
      195.46% 61.49% at 21.04% 57.87%,
      rgba(248, 249, 255, 0.75) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      244deg,
      rgba(212, 219, 254, 0) 68.71%,
      rgba(232, 214, 255, 0.8) 100%
    ),
    linear-gradient(102deg, #c7e3ff 0%, #e0e8ff 50%, #ccd3fc 100%);
  box-shadow: 0px 4px 6px 0px #ffffff66 inset;
  ${Media.desktop`
    gap: 24px;
    margin-top: 32px;
  `}
  ${Media.ipad`
    height: 312px;
    padding: 16px 16px 16px 40px;
    margin-top: 24px;
    border-radius: 20px;
    img {
      width: 480px;
      height: 280px;
    }
  `}
  ${Media.phone`
    display: none;
  `}
`
export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 492px;
  height: 352px;
  h3 {
    font-size: 32px;
    color: #1f2329;
    font-weight: 700;
  }
  ul {
    margin-top: 24px;
    padding-left: 16px;
    li {
      list-style-type: disc;
      font-size: 18px;
      font-weight: 400;
      color: #595959;
      line-height: 25.2px;
      &::marker {
        font-size: 16px;
      }
    }
    // 除第一个li外，其他li的样式
    li + li {
      margin-top: 16px;
    }
  }
  ${Media.homeLaptop`
    h3 {
      font-size: 30px;
    }
  `}
  ${Media.desktop`
    h3 {
      font-size: 28px;
    }
  `}
  ${Media.ipad`
    width: 400px;
    height: 280px;
    h3 {
      font-size: 24px;
    }
    ul {
      margin-top: 16px;
      li {
        font-size: 16px;
      }
      li + li {
        margin-top: 12px;
      }
    }
  `}
`

export const SceneMainMobile = styled.div`
  display: none;

  ${Media.phone`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.18rem;
    margin-top: 0.48rem;
  `}
`
export const MainItemMobile = styled.div<{ bg: string; index: number }>`
  width: 6.86rem;
  height: 6.18rem;
  background-image: ${({ bg }) => `url(${bg})`};
  background-size: cover;
  background-position: center;
  border-radius: 0.24rem;
  position: relative;
  ${({ index }) => index === 0 && 'height: 6.58rem;'}
  h3 {
    margin-top: 0.42rem;
    margin-left: 0.42rem;
    font-size: 0.4rem;
    color: #1f2329;
    font-weight: 700;
  }
  .title {
    margin-top: 0.08rem;
    margin-left: 0.42rem;
    font-size: 0.32rem;
    color: #1f2329;
    width: 4.4rem;
    line-height: normal;
  }
  ul {
    position: absolute;
    width: 6.54rem;
    background-color: #fff;
    border-radius: 0.16rem;
    left: 0.16rem;
    bottom: 0.16rem;
    padding: 0.32rem 0.32rem 0.32rem 0.68rem;
    li {
      width: 5.6rem;
      list-style-type: disc;
      font-size: 0.28rem;
      line-height: 0.39rem;
      color: #595959;
      &::marker {
        font-size: 0.2rem;
      }
    }
    li + li {
      margin-top: 0.16rem;
    }
  }
`
