import React from 'react'
import Arrow from '../../../../../images/products/business/arrowIcon.svg'
import Check from '../../../../../images/products/business/check-icon.svg'
import { Title } from '../../../business/atoms'
import NotFoldTable from '../../../components/noFoldTable'
import { tableList, KeyEnum } from '../../config'
import { LearnMore } from '../../../business/component/explore/atom'
import { ComparetableWrap, TableTitle } from './atoms'
import Path from '@raylink-overseas/common/constants/path'

export default function ProductsCompare() {
  const officeTitle = ({
    title,
    href,
    btnText,
  }: {
    title: string
    href: string
    btnText: string
  }) => (
    <TableTitle>
      <div className="t-title">{title}</div>
      <a
        href={href}
        className={`btn ${title === 'Free' ? 'line' : ''}`}
        target="_blank"
        rel="noreferrer"
      >
        {btnText}
      </a>
    </TableTitle>
  )

  const titleList = [
    {
      label: officeTitle({
        title: 'Free',
        href: Path.downloads,
        btnText: 'Free Download',
      }),
      key: KeyEnum.free,
    },
    {
      label: officeTitle({
        title: 'Pro',
        href: Path.pricing,
        btnText: 'See Pricing',
      }),
      key: KeyEnum.pro,
    },
    // 等待premium产品上线
    // {
    //   label: officeTitle({
    //     title: 'Premium',
    //     href: Path.pricing,
    //     btnText: 'See Pricing',
    //   }),
    //   key: KeyEnum.premium,
    // },
  ]

  return (
    <ComparetableWrap>
      <Title className="c-title">Free Trial or Pay for Needs</Title>
      <NotFoldTable
        compareList={tableList}
        titleList={titleList}
        checkImg={Check}
        headerText=""
      />
      <LearnMore className="learn-more">
        <a href={Path.pricing} target="_blank" rel="noreferrer">
          Learn More
        </a>
        <img className="arrow-icon" alt="" src={Arrow} loading="lazy" />
      </LearnMore>
    </ComparetableWrap>
  )
}
