import React from 'react'
import AvailableList from '@raylink-overseas/common/components/availableList'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import Path from '@raylink-overseas/common/constants/path'
import { BannerContent, BannerWrapper, Left, Right } from './atoms'
import BannerMainImg from '../../../../../images/products/personal/bannerMain.png'

const PersonalBanner = () => {
  const data = {
    tag: 'Avica Personal',
    title: 'Your Smart Choice for Remote Desktop App',
    desc: `Experience premium remote control with Avica remote desktop app's
            superior performance and user-friendly design, the top choice for
            personal users.`,
  }
  return (
    <BannerWrapper>
      <BannerContent>
        <Left>
          <div className="tag">{data.tag}</div>
          <h1 className="title">{data.title}</h1>
          <div className="desc">{data.desc}</div>
          <ButtonList
            className="btn-list-div"
            btnInfo={[
              { text: 'Free Download', href: Path.downloads },
              {
                text: 'See Pricing',
                href: Path.pricing,
              },
            ]}
          />
          <AvailableList className="available-list" />
        </Left>
        <Right src={BannerMainImg} alt={data.tag} loading="lazy" />
      </BannerContent>
    </BannerWrapper>
  )
}
export default PersonalBanner
