import styled from 'styled-components'
import { NewWrapper } from '@raylink-overseas/common/components'
import { Media } from '@raylink-overseas/common/theme'

export const ProductsIntroduceWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 0;
    margin-bottom: 56px;
  }
  // 由于设计稿切图问题，视觉高度要比实际高度高，所以title需重写 margin
  .priority-title {
    margin-top: 48px;
    margin-bottom: 56px;
  }
  ${Media.homeLaptop`
    .title, .priority-title {
      margin-bottom: 48px;
    }
    .priority-title {
      margin-top: 40px;
    }
  `}
  ${Media.laptop`
    .title, .priority-title {
      margin-bottom: 40px;
    }
  `}
  ${Media.ipad`
    width: 602px;
    margin: 0 auto;
    .title, .priority-title {
      margin-bottom: 32px;
    }
    .priority-title {
      margin-top: 30px;
    }
  `}
  ${Media.phone`
    margin-top: 0.8rem;
    margin-bottom: 0.48rem;
    width: 6.86rem;
    .title, .priority-title {
      margin-bottom: 0.48rem;
      margin-top: 0;
    }
    .desc {
      font-size: 0.28rem;
    }
  `}
`

export const IntroduceWeb = styled(NewWrapper)<{ reserve: boolean }>`
  display: flex;
  justify-content: space-between;
  .right {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 436px;
  }
  .left {
    width: 722px;
    height: 470px;
    // 解决图片边框有空白问题
    margin-top: -25px;
  }
  ${({ reserve }) =>
    reserve
      ? 'flex-direction: row-reverse;padding-bottom: 45px;'
      : 'flex-direction: row;'}
  ${Media.homeLaptop<{ reserve: boolean }>`
    .left {
      width: 681px;
      height: 446px;
    }
    ${({ reserve }) => (reserve ? 'padding-bottom: 24px;' : '')}
  `}
  ${Media.laptop<{ reserve: boolean }>`
    .left {
      width: 657px;
      height: 430px;
    }
    ${({ reserve }) => (reserve ? 'padding-bottom: 12px;' : '')}
  `}
  ${Media.ipad<{ reserve: boolean }>`
    .left {
      width: 524px;
      height: 342px;
      margin-top: -20px;
    }
    .right {
      gap: 8px;
    }
    ${({ reserve }) => (reserve ? 'padding-bottom: 16px;' : '')}
  `}
  ${Media.phone`
    display: none;
  `}
`
export const IntroduceItem = styled.div<{
  isActive: boolean
}>`
  padding: 16px 24px;
  cursor: pointer;
  .e-title {
    font-size: 24px;
    font-weight: 700;
    color: #1f2329;
  }
  .e-desc {
    font-size: 16px;
    color: #595959;
    font-weight: 400;
    margin-top: 8px;
  }

  ${({ isActive }) =>
    isActive
      ? `
        padding: 24px 24px 32px;
        border-radius: 16px;
        border: 2px solid #e4f4fe;
        background: linear-gradient(
            135deg,
            rgba(228, 244, 254, 0) 52.59%,
            rgba(218, 229, 255, 0.38) 79.09%,
            rgba(208, 215, 255, 0.5) 103.79%,
            rgba(208, 215, 255, 0.5) 143.03%
          ),
          #fff;
        box-shadow: 0px 16px 40px 2px rgba(240, 245, 255, 0.7);
      `
      : ''}
  ${Media.ipad<{ isActive: boolean }>`
    padding: 12px 20px 12px 24px;
    .e-title {
      font-size: 20px;
    }
    ${({ isActive }) => isActive && 'padding: 20px 20px 20px 24px;'}
  `}
`

export const IntroduceMobile = styled.div<{ isPriority: boolean }>`
  display: none;
  ${Media.phone<{ isPriority: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.88rem;
    gap: 0.32rem;
    ${({ isPriority }) => (isPriority ? 'padding-bottom: 0.4rem;' : '')}
  `}
`

export const IntroduceMobileItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 6.88rem;
  padding: 16px 16px 16px 32px;
  border-radius: ${({ isActive }) => (isActive ? '24px' : '8px')};
  border: 2px solid #e4f4fe;
  box-shadow: 0px 16px 40px 2px #f0f5ffb2;

  background: linear-gradient(
      173deg,
      rgba(228, 244, 254, 0) 62.74%,
      rgba(218, 229, 255, 0.76) 93.48%,
      #d0d7ff 143.03%
    ),
    #fff;

  .l-title {
    font-size: 20px;
    font-weight: 700;
    color: #1f2329;
    cursor: pointer;
  }
  .l-desc {
    margin-top: 8px;
    font-size: 16px;
    color: #595959;
  }
  .right-img {
    width: 330px;
  }
  ${Media.phone<{ isActive: boolean }>`
    flex-direction: column;
    gap: 0.48rem;
    padding: ${({ isActive }) => (isActive ? '0.48rem' : '0.32rem 0')};
    border-radius: ${({ isActive }) => (isActive ? '0.24rem' : '0.16rem')};
    border: 0.04rem solid #e4f4fe;
    .l-title {
      font-size: 0.36rem;
    }
    .l-desc {
      margin-top: 0.16rem;
      font-size: 0.28rem;
    }
    .right-img {
      width: 5.92rem;
    }
  `}
`
