import React, { useEffect, useState } from 'react'
import { Title } from '../../../business/atoms'
import { SimpleWrap, StepWrap, StepItem } from './atoms'
import Step1Src from '@images/home/step1.png'
import Step2Src from '@images/home/step2.png'
import Step3Src from '@images/home/step3.png'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import Path from '@raylink-overseas/common/constants/path'

const stepList = [
  {
    name: 'Step 1',
    feature: 'Install',
    text: `Install Avica app on the devices you want to remote connect.`,
    imgSrc: Step1Src,
  },
  {
    name: 'Step 2',
    feature: 'Login',
    text: `Register your Avica account and log in.`,
    imgSrc: Step2Src,
  },
  {
    name: 'Step 3',
    feature: 'Connect',
    text: `Enter remote device's Avica ID on your device and start a remote connection.`,
    imgSrc: Step3Src,
  },
]

export default function ProductsFeature({ isPlay }: { isPlay: boolean }) {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!isPlay) return undefined
    const interval = setInterval(() => {
      if (activeIndex === 2) {
        setActiveIndex(0)
      } else {
        setActiveIndex(activeIndex + 1)
      }
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [activeIndex, isPlay])

  return (
    <SimpleWrap>
      <Title className="title">
        Simple is the Best
        <div className="desc">
          Everyone can Avica! With only 3 steps to remote access to computers or
          mobile devices.
        </div>
      </Title>

      <StepWrap id="feature-id">
        {stepList.map((item, index) => (
          <StepItem key={index} isActive={index === activeIndex}>
            <div className={index === 1 ? 'cross-line' : undefined}>
              <div className="btn">{item.name}</div>
            </div>
            <div className="feature-desc-div">
              <div className="feature">{item.feature}</div>
              <div className="item-desc">{item.text}</div>
            </div>
            <div className="img-div">
              <img src={item.imgSrc} alt="" loading="lazy" />
            </div>
          </StepItem>
        ))}
      </StepWrap>
      <ButtonList
        className="btn-list"
        btnInfo={[
          {
            text: 'Free Download',
            href: Path.downloads,
          },
          {
            text: 'See Pricing',
            href: Path.pricing,
          },
        ]}
      />
    </SimpleWrap>
  )
}
