import styled from 'styled-components'
import { NewWrapper } from '@raylink-overseas/common/components'
import { Media, color } from '@raylink-overseas/common/theme'

export const SimpleWrap = styled.div`
  background: linear-gradient(180deg, #f1f3ff 0%, #f9faff 80%, #fff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  // 由于设计稿中此模块有渐变背景，与下一个模块的间距之间有背景色，所以须将间距设置在次模块内
  padding-bottom: 112px;
  .btn-list {
    margin-top: 56px;
  }
  ${Media.homeLaptop`
    padding-bottom: 88px;
    .btn-list {
      margin-top: 46px;
    }
  `}
  ${Media.laptop`
    padding-bottom: 64px;
  `}
  ${Media.ipad`
    padding-bottom: 56px;
    .btn-list {
      margin-top: 30px;
    }
  `}
  ${Media.phone`
    padding-bottom: 0.8rem;
    .title {
      width: 6.86rem;
      .desc {
        font-size: 0.28rem;
      }
    }
    .btn-list {
      display: flex;
      gap: 0.32rem;
      margin-top: 1.1rem;
    }
  `}
`

export const StepWrap = styled(NewWrapper)`
  display: flex;
  justify-content: center;
  gap: 24px;
  ${Media.ipad`
    gap: 16px;
  `}
  ${Media.phone`
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-width: auto;
    height: 5.46rem;
    width: 6.42rem;
  `}
`

export const StepItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  .btn {
    width: 112px;
    text-align: center;
    border-radius: 60px;
    font-weight: 500;
    transition: all 0.4s ease-in;
    color: ${({ isActive }) => (isActive ? '#fff' : color.primary)};
    padding: 10px 0;
    background: ${({ isActive }) =>
      isActive
        ? `linear-gradient(290deg, #445fff 53.77%, #55bcee 102.07%)`
        : `rgba(68, 95, 255, 0.1)`};
    box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset,
      0px -3px 10px 0px rgba(68, 95, 255, 0.04) inset;
    white-space: nowrap;
  }
  .cross-line {
    position: relative;
    ::before,
    ::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 296px;
      height: 2px;
      border-bottom: 2px dashed rgba(68, 95, 255, 0.15);
    }
    &::before {
      left: -296px;
    }
    &::after {
      left: 112px;
    }
  }
  .feature {
    font-size: 24px;
    text-align: center;
    transition: all 0.4s ease-in;
    color: ${({ isActive }) => (isActive ? color.primary : '#1f2329')};
    font-weight: 700;
    margin: 32px 0 16px;
  }
  .feature-desc-div {
    width: 298px;
  }
  .item-desc {
    color: ${color.textDark};
    height: 40px;
    margin: 0 auto;
    margin-bottom: 32px;
    text-align: center;
  }
  .img-div {
    width: 384px;
    height: 302px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ isActive }) => (isActive ? '#fff' : 'transparent')};
    img {
      width: 368px;
      height: 286px;
    }
  }
  ${Media.homeLaptop`
    .feature {
      font-size: 22px;
      margin: 24px 0 12px;
    } 
    .cross-line {
      ::before,
      ::after {
        width: 283px;
      }
      &::before {
        left: -283px;
      }
      &::after {
        left: 112px;
      }
    }
    .img-div {
      width: 373px;
      height: 295px;
      border-radius: 24px;
      img {
        width: 353px;
        height: 275px;
      }
    }
  `}
  ${Media.laptop`
    .item-desc {
      width: 324px;
      margin-bottom: 24px;
    }
    .feature {
      font-size: 20px;
      margin: 16px 0 12px;
    } 
    .cross-line {
      ::before,
      ::after {
        width: 256px;
      }
      &::before {
        left: -256px;
      }
      &::after {
        left: 112px;
      }
    }
    .img-div {
      width: 345px;
      height: 273px;
      img {
        width: 325px;
        height: 253px;
      }
    }
  `}

  ${Media.ipad`
    .btn {
      width: 88px;
      padding: 8px 0;
    }
    .item-desc {
      width: 240px;
      height: 60px;
    }
    .feature {
      margin: 16px 0 8px;
    }
    .cross-line {
      ::before,
      ::after {
        width: 222px;
      }
      &::before {
        left: -222px;
      }
      &::after {
        left: 88px;
      }
    }
    .img-div {
      width: 277px;
      height: 220px;
      border-radius: 16px;
      img {
        width: 257px;
        height: 200px;
      }
    }
  `}
  ${Media.phone<{ isActive: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 0.28rem;
    justify-content: space-between;
    gap: 0.32rem;
    .btn {
      width: 1.36rem;
      height: 0.68rem;
      border-radius: 1.2rem;
      padding: 0.16rem 0;
    }
    .cross-line {
      ::before,
      ::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(0);
        width: 0.02rem;
        height: 1.44rem;
        border-bottom: none;
        border-left: 0.02rem dashed rgba(68, 95, 255, 0.15);
      }
      &::before {
        left: 50%;
        top: -1.44rem;
      }
      &::after {
        top: 0.68rem;
      }
    }
    .feature {
      font-size: 0.36rem;
      text-align: left;
      margin: 0 0 0.16rem;
    }
    .item-desc {
      color: ${color.textDark};
      width: 4.74rem;
      height: 1.08rem;
      margin-bottom: 0.18rem;
      text-align: left;
    }
    .img-div {
      display: none;
    }
  `}
`
