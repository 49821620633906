import { NewWrapper } from '@raylink-overseas/common/components'
import styled from 'styled-components'
import Bg from '../../../../../images/products/personal/avica-best-bg.png'
import BgMobile from '../../../../../images/products/personal/avica-bg-mobile.png'
import { Media } from '@raylink-overseas/common/theme'

export const Wrapper = styled(NewWrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 330px;
  border-radius: 24px;
  margin: 112px auto;
  overflow: hidden;
  ${Media.homeLaptop`
    margin: 88px auto;
  `}
  ${Media.laptop`
    height: 298px;
    margin: 64px auto;
  `}
  ${Media.ipad`
    margin: 56px;
  `}
  ${Media.phone`
    margin: 0.8rem 0 0 0;
    border-radius: 0;
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${Bg});
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  h2 {
    font-size: 36px;
    font-weight: 800;
    line-height: 46.85px;
    color: #1f2329;
    margin: 24px 0 32px 0;
    span {
      background: linear-gradient(93.36deg, #445fff 73.62%, #55bcee 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  ${Media.ipad`
    h2 {
      font-size: 30px;
      margin: 16px 0 24px 0;
    }
  `}
  ${Media.phone`
    background-image: url(${BgMobile});
    img {
      width: 2.56rem;
      height: 0.72rem;
    }
    h2 {
      font-size: 0.56rem;
      margin: 16px 0 24px 0;
      text-align: center;
      line-height: 0.72rem;
    }
  `}
`
