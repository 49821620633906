import React, { useEffect, useState } from 'react'
import { Title } from '../../../business/atoms'
import {
  ProductsIntroduceWrap,
  IntroduceWeb,
  IntroduceItem,
  IntroduceMobile,
  IntroduceMobileItem,
} from './atoms'
import { RemoteExperience, FirstPriority } from '../../config'

const params = {
  remoteExperience: RemoteExperience,
  firstPriority: FirstPriority,
}

export default function ProductsIntroduce({
  playStatus,
  type,
  ...rest
}: {
  playStatus: boolean
  type: 'remoteExperience' | 'firstPriority'
}) {
  const configData = params[type]
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!playStatus) return undefined
    const interval = setTimeout(() => {
      if (activeIndex === configData.list.length - 1) {
        setActiveIndex(0)
      } else {
        setActiveIndex(pre => pre + 1)
      }
    }, 3000)
    return () => {
      clearInterval(interval)
    }
  }, [playStatus, activeIndex])

  return (
    <ProductsIntroduceWrap {...rest}>
      <Title
        className={type === 'remoteExperience' ? 'title' : 'priority-title'}
      >
        {configData.title}
        <p className="desc">{configData.desc}</p>
      </Title>

      <div id={type === 'remoteExperience' ? 'intro-one-id' : 'intro-two-id'}>
        {/* pc端 */}
        <IntroduceWeb reserve={type === 'firstPriority'}>
          <img
            src={configData.list[activeIndex]?.img}
            alt=""
            className="left"
            loading="lazy"
          />
          <div className="right">
            {configData.list.map((item, index) => (
              <IntroduceItem
                key={item.title}
                isActive={activeIndex === index}
                onClick={() => setActiveIndex(index)}
              >
                <h3 className="e-title">{item.title}</h3>
                {activeIndex === index && (
                  <div className="e-desc">{item.desc}</div>
                )}
              </IntroduceItem>
            ))}
          </div>
        </IntroduceWeb>

        {/* ipad及手机端 */}
        <IntroduceMobile isPriority={type === 'firstPriority'}>
          {configData.list.map((item, index) => (
            <IntroduceMobileItem
              key={item.title}
              isActive={activeIndex === index}
              onClick={() => setActiveIndex(index)}
            >
              {activeIndex === index ? (
                <>
                  <div className="left">
                    <div className="l-title">{item.title}</div>
                    <div className="l-desc">{item.desc}</div>
                  </div>
                  <img
                    src={item.img}
                    alt=""
                    className="right-img"
                    loading="lazy"
                  />
                </>
              ) : (
                <div className="l-title">{item.title}</div>
              )}
            </IntroduceMobileItem>
          ))}
        </IntroduceMobile>
      </div>
    </ProductsIntroduceWrap>
  )
}
