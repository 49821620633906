// 介绍模块图
import UltraLowLatencyImg from '../../../images/products/personal/introduce/UltraLowLatency.png'
import HighQualityImageImg from '../../../images/products/personal/introduce/HighQualityImage.png'
import GlobalAccessNetworkImg from '../../../images/products/personal/introduce/GlobalAccessNetwork.png'
import CrossPlatformImg from '../../../images/products/personal/introduce/CrossPlatform.png'
import EncryptionImg from '../../../images/products/personal/introduce/EncryptionTech.png'
import PrivacyProtectionImg from '../../../images/products/personal/introduce/PrivacyProtection.png'
import PermissionManagementImg from '../../../images/products/personal/introduce/PermissionManage.png'

// 场景模块图
import RemoteWorkImg from '../../../images/products/personal/scene/RemoteWork.png'
import RemoteGamingImg from '../../../images/products/personal/scene/RemoteGaming.png'
import MobileDeviceSupportImg from '../../../images/products/personal/scene/MobileDeviceSupport.png'
import RemoteDesignImg from '../../../images/products/personal/scene/RemoteDesign.png'
import RemoteWorkHoverImg from '../../../images/products/personal/scene/RemoteWorkHover.png'
import RemoteGamingHoverImg from '../../../images/products/personal/scene/RemoteGamingHover.png'
import MobileDeviceSupportHoverImg from '../../../images/products/personal/scene/MobileDeviceSupportHover.png'
import RemoteDesignHoverImg from '../../../images/products/personal/scene/RemoteDesignHover.png'
import WorkImg from '../../../images/products/personal/scene/Work.png'
import GamingImg from '../../../images/products/personal/scene/Gaming.png'
import SupportImg from '../../../images/products/personal/scene/Support.png'
import DesignImg from '../../../images/products/personal/scene/Design.png'
import WorkMobileImg from '../../../images/products/personal/scene/RemoteWorkMobile.png'
import GamingMobileImg from '../../../images/products/personal/scene/RemoteGamingMobile.png'
import SupportMobileImg from '../../../images/products/personal/scene/MobileDeviceSupportMobile.png'
import DesignMobileImg from '../../../images/products/personal/scene/RemoteDesignMobile.png'

export const RemoteExperience = {
  title: 'Superior Remote Access Experience',
  desc: 'Boost your productivity and maintain control from anywhere with our top-notch software for remote access.',
  list: [
    {
      title: 'Ultra-Low Latency',
      desc: 'Enjoy real-time responsive action with latency as low as 10ms, providing a smooth and seamless local-like operating experience.',
      img: UltraLowLatencyImg,
    },
    {
      title: 'High-Quality Image',
      desc: 'Immerse yourself in the premium quality of visuals, ensuring every detail from your screen is showcased with utmost clarity.',
      img: HighQualityImageImg,
    },
    {
      title: 'Global Access Network',
      desc: 'Benefit from deployed servers that cover multiple regions worldwide, ensuring stable and uninterrupted remote connections.',
      img: GlobalAccessNetworkImg,
    },
    {
      title: 'Cross-Platform',
      desc: 'Effortlessly access devices such as computers, smartphones, and tablets from any location, combining work and entertainment seamlessly.',
      img: CrossPlatformImg,
    },
  ],
}

export const FirstPriority = {
  title: 'Security is the First Priority',
  desc: 'Avica remote access software is designed with your privacy in mind, ensuring robust data security at every level.',
  list: [
    {
      title: 'Encryption Tech',
      desc: 'Use advanced end-to-end AES-256 encryption protocol, ensuring data security and strictly preventing data theft and leaks.',
      img: EncryptionImg,
    },
    {
      title: 'Privacy Protection',
      desc: 'Deploy various security measures such as privacy mode and anti-harassment mode, automatic locking desktop after session, etc. ensuring personalized security.',
      img: PrivacyProtectionImg,
    },
    {
      title: 'Permission Management',
      desc: 'Efficiently protects the host side, providing them the authority to limit actions on the client side, thus rejecting any malicious remote control.',
      img: PermissionManagementImg,
    },
  ],
}

export const KeyEnum = {
  free: 'free',
  pro: 'pro',
  premium: 'premium',
}

// 未含有premium
export const tableList = [
  {
    name: 'Channel',
    rows: [
      { key: KeyEnum.free, value: '1' },
      { key: KeyEnum.pro, value: '1' },
    ],
  },
  {
    name: 'Concurrent session',
    rows: [
      { key: KeyEnum.free, value: '1' },
      { key: KeyEnum.pro, value: '5' },
    ],
  },
  {
    name: 'Resolutions',
    rows: [
      { key: KeyEnum.free, value: 'Smooth' },
      { key: KeyEnum.pro, value: 'Ultra HD' },
    ],
  },
  {
    name: 'Frame Rate',
    rows: [
      { key: KeyEnum.free, value: 'Up to 30FPS' },
      { key: KeyEnum.pro, value: 'Up to 144FPS' },
    ],
  },
  {
    name: 'Color Mode',
    rows: [
      { key: KeyEnum.free, value: '4 : 2 : 0' },
      { key: KeyEnum.pro, value: '4 : 4 : 4' },
    ],
  },
  {
    name: 'File Transfer',
    rows: [
      { key: KeyEnum.free, value: true },
      { key: KeyEnum.pro, value: true },
    ],
  },
  {
    name: 'Multi Monitor Support',
    rows: [
      { key: KeyEnum.free, value: true },
      { key: KeyEnum.pro, value: true },
    ],
  },
  {
    name: 'Mobile Device Support',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.pro, value: true },
    ],
  },
  {
    name: 'Digitizer',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.pro, value: true },
    ],
  },
  {
    name: 'Gamepad',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.pro, value: true },
    ],
  },
  {
    name: 'Game Keyboard',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.pro, value: true },
    ],
  },
  {
    name: 'Privacy Mode',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.pro, value: true },
    ],
  },
  {
    name: 'Screen Recording',
    rows: [
      { key: KeyEnum.free, value: false },
      { key: KeyEnum.pro, value: true },
    ],
  },
]
// 含有premium
// export const tableList = [
//   {
//     name: 'Channel',
//     rows: [
//       { key: KeyEnum.free, value: '1' },
//       { key: KeyEnum.pro, value: '1' },
//       { key: KeyEnum.premium, value: '1' },
//     ],
//   },
//   {
//     name: 'Concurrent session',
//     rows: [
//       { key: KeyEnum.free, value: '1' },
//       { key: KeyEnum.pro, value: '5' },
//       { key: KeyEnum.premium, value: '5' },
//     ],
//   },
//   {
//     name: 'Image Quality',
//     rows: [
//       { key: KeyEnum.free, value: 'Smooth' },
//       { key: KeyEnum.pro, value: 'HD' },
//       { key: KeyEnum.premium, value: 'Ultra HD' },
//     ],
//   },
//   {
//     name: 'Frame Rate',
//     rows: [
//       { key: KeyEnum.free, value: '30 FPS' },
//       { key: KeyEnum.pro, value: '60 FPS' },
//       { key: KeyEnum.premium, value: '144 FPS' },
//     ],
//   },
//   {
//     name: 'Color Mode',
//     rows: [
//       { key: KeyEnum.free, value: '4 : 2 : 0' },
//       { key: KeyEnum.pro, value: '4 : 4 : 4' },
//       { key: KeyEnum.premium, value: '4 : 4 : 4' },
//     ],
//   },
//   {
//     name: 'File Transfer',
//     rows: [
//       { key: KeyEnum.free, value: 'Up to 5M/bps' },
//       { key: KeyEnum.pro, value: 'Unlimited Bandwidth' },
//       { key: KeyEnum.premium, value: 'Unlimited Bandwidth' },
//     ],
//   },
//   {
//     name: 'Screen Mirroring',
//     rows: [
//       { key: KeyEnum.free, value: 'Limited-Time' },
//       { key: KeyEnum.pro, value: true },
//       { key: KeyEnum.premium, value: true },
//     ],
//   },
//   {
//     name: 'Multi-to-Multi Monitor',
//     rows: [
//       { key: KeyEnum.free, value: false },
//       { key: KeyEnum.pro, value: true },
//       { key: KeyEnum.premium, value: true },
//     ],
//   },
//   {
//     name: 'Mobile Device Support',
//     rows: [
//       { key: KeyEnum.free, value: false },
//       { key: KeyEnum.pro, value: true },
//       { key: KeyEnum.premium, value: true },
//     ],
//   },
//   {
//     name: 'Screen Recording',
//     rows: [
//       { key: KeyEnum.free, value: false },
//       { key: KeyEnum.pro, value: true },
//       { key: KeyEnum.premium, value: true },
//     ],
//   },
//   {
//     name: 'Privacy Mode',
//     rows: [
//       { key: KeyEnum.free, value: false },
//       { key: KeyEnum.pro, value: true },
//       { key: KeyEnum.premium, value: true },
//     ],
//   },
//   {
//     name: 'Digitizer',
//     rows: [
//       { key: KeyEnum.free, value: false },
//       { key: KeyEnum.pro, value: true },
//       { key: KeyEnum.premium, value: true },
//     ],
//   },
//   {
//     name: 'Remote Gaming Features',
//     rows: [
//       { key: KeyEnum.free, value: false },
//       { key: KeyEnum.pro, value: false },
//       { key: KeyEnum.premium, value: true },
//     ],
//   },
// ]

export const SceneList = [
  {
    icon: RemoteWorkImg,
    hoverIcon: RemoteWorkHoverImg,
    name: 'Remote Work',
    title: 'Built-in Tools for Empowering Efficiency',
    list: [
      'Easily transfer files across platforms, without limitations on quantity, format or size.',
      `Utilize Privacy Mode to mute and black out remote device's screen, preventing prying eyes.`,
      'Supports multiple monitors for remote access, improving work efficiency.',
    ],
    mainImg: WorkImg,
    mobileImg: WorkMobileImg,
  },
  {
    icon: RemoteGamingImg,
    hoverIcon: RemoteGamingHoverImg,
    name: 'Remote Gaming',
    title: 'Access Your Favorite Games Anytime, Anywhere',
    list: [
      'Enjoy gaming with a performance of up to 144FPS.',
      'Control adjustable 3D perspectives for the immersive, on-site gaming experience.',
      ' Customized mobile keyboards enable PC gaming on your phone.',
    ],
    mainImg: GamingImg,
    mobileImg: GamingMobileImg,
  },
  {
    icon: MobileDeviceSupportImg,
    hoverIcon: MobileDeviceSupportHoverImg,
    name: 'Mobile Device Support',
    title: 'Mobile Remote Control is Now Easy, Precise, and Secure',
    list: [
      ' Access a desktop from your Android/iOS devices or vice versa.',
      'Features mouse pointer mode for accurate clicks.',
      'Stable remote connections make it easy to work on your phone.',
    ],
    mainImg: SupportImg,
    mobileImg: SupportMobileImg,
  },
  {
    icon: RemoteDesignImg,
    hoverIcon: RemoteDesignHoverImg,
    name: 'Remote Design',
    title: 'Ultimate Image Quality Empowers Your Creativity',
    list: [
      'Compatible with common design software on the market.',
      '4:4:4 color mode reproduces images with accurate and realistic colors.',
      'Works seamlessly with external digital tablets, sensitively responding to pressure.',
    ],
    mainImg: DesignImg,
    mobileImg: DesignMobileImg,
  },
]
