import React, { useEffect, useRef, useState } from 'react'
import {
  SceneContent,
  SceneWrapper,
  SceneTitle,
  TitleItem,
  TitleImg,
  SceneMain,
  LeftContent,
  SceneMainMobile,
  MainItemMobile,
} from './atoms'
import { LearnMore } from '../../../business/component/explore/atom'
import Arrow from '../../../../../images/products/business/arrowIcon.svg'
import { Title } from '../../../business/atoms'
import Path from '@raylink-overseas/common/constants/path'
import { SceneList } from '../../config'

const ProductsScene = ({ isPlay }: { isPlay: boolean }) => {
  const [activateIndex, setActivateIndex] = useState(0)
  const timer = useRef<any>(null)
  const startTimer = () => {
    timer.current = setInterval(() => {
      setActivateIndex(pre => (pre + 1) % SceneList.length)
    }, 5000)
  }
  const stopTimer = () => {
    clearInterval(timer.current)
    timer.current = null
  }
  useEffect(() => {
    if (!isPlay) return undefined
    startTimer()
    return () => {
      stopTimer()
    }
  }, [isPlay])
  return (
    <SceneWrapper>
      <SceneContent id="scene-id">
        <Title className="title">
          The Go-To Choice for Remote Access Software
          <LearnMore className="desc">
            <a href={Path.allFeatures} target="_blank" rel="noreferrer">
              Discover More Features
            </a>
            <img className="arrow-icon" alt="" src={Arrow} loading="lazy" />
          </LearnMore>
        </Title>
        <SceneTitle>
          {SceneList.map((item, index) => (
            <TitleItem
              activate={activateIndex === index}
              key={index}
              onClick={() => {
                stopTimer()
                setActivateIndex(index)
                startTimer()
              }}
            >
              <TitleImg
                activate={activateIndex === index}
                img={item.icon}
                hoverImg={item.hoverIcon}
              />
              <div className="name">{item.name}</div>
            </TitleItem>
          ))}
        </SceneTitle>
        <SceneMain>
          <LeftContent>
            <h3>{SceneList[activateIndex].title}</h3>
            <ul>
              {SceneList[activateIndex].list.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </LeftContent>
          <img
            src={SceneList[activateIndex].mainImg}
            alt={SceneList[activateIndex].name}
            loading="lazy"
          />
        </SceneMain>
        <SceneMainMobile>
          {SceneList.map((item, index) => (
            <MainItemMobile index={index} bg={item.mobileImg} key={index}>
              <h3>{item.name}</h3>
              <div className="title">{item.title}</div>
              <ul>
                {item.list.map((child, i) => (
                  <li key={i}>{child}</li>
                ))}
              </ul>
            </MainItemMobile>
          ))}
        </SceneMainMobile>
      </SceneContent>
    </SceneWrapper>
  )
}
export default ProductsScene
